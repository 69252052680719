<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            :disabled="disableSaveButton"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
          />
          <Button
            v-if="saldo > 0"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Rincian"
            @click="add"
          />
          <Button
            v-if="saldo === 0"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Rincian"
            style="color: grey"
            @click="add"
            :disabled="true"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'CashOut' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-6">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.kk_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-6">
                <label>No Bukti</label>
                <InputText v-model="form.kk_no" class="w-full" autofocus />
              </div>
              <div class="field col-12 md:col-6">
                <label
                  :class="{
                    'p-error': v$.form.kas.$invalid && submitted,
                  }"
                >
                  Kode Kas/Bank
                </label>
                <Dropdown
                  v-model="v$.form.kas.$model"
                  placeholder="Kode Kas/Bank"
                  :options="list_kas"
                  :class="{
                    'p-invalid': v$.form.kas.$invalid && submitted,
                  }"
                  class="w-full"
                  @change="onLoadSaldo()"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      {{ slotProps.value.nama }}
                    </div>
                    <div v-else>
                      {{ slotProps.placeholder }}
                    </div>
                  </template>
                  <template #option="slotProps">
                    <div>
                      <small>{{ slotProps.option.kode }}</small>
                      <span>&nbsp;</span>
                      <span>{{ slotProps.option.nama }}</span>
                    </div>
                  </template>
                </Dropdown>
                <small
                  v-if="
                    (v$.form.kas.$invalid && submitted) ||
                    v$.form.kas.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.kas.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-6">
                <label> Saldo </label>
                <InputNumber
                  v-model="saldo"
                  :minFractionDigits="0"
                  mode="currency"
                  currency="IDR"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12">
                <label
                  :class="{
                    'p-error': v$.form.keterangan.$invalid && submitted,
                  }"
                  >Keterangan</label
                >
                <InputText v-model="v$.form.keterangan.$model" class="w-full" />
                <small
                  v-if="
                    (v$.form.keterangan.$invalid && submitted) ||
                    v$.form.keterangan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.keterangan.required.$message }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <grid-pengeluaran-detail
          :items="form.detail"
          :loading="isLoading"
          @edit="onEditDetail"
          @delete="onConfirmDeletion"
        />
      </div>
    </form>
    <Dialog
      :header="detailIndex >= 0 ? 'Edit Rincian' : 'Tambah Rincian'"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-perkiraan
        :item="detail"
        :saldo="saldo"
        @close="onCloseFormDetail"
        @save="onSaveDetail"
      />
    </Dialog>
    <Dialog
      header="Hapus Rincian"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div>
        <span
          >Rincian <strong>{{ detail.account.nama }}</strong> akan dihapus dari
          daftar rincian. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogDelete = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteDetail"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import DaftarKasService from '@/services/DaftarKasService'
import AccountService from '@/services/AccountService'
import errorHandler from '@/helpers/error-handler'
import FormPerkiraan from '@/components/keuangan/FormPerkiraan'
import GridPengeluaranDetail from '@/components/keuangan/GridPengeluaranDetail'
import Hotkey from '@/components/Hotkey'
import { UUID } from 'uuidjs'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormPerkiraan,
    GridPengeluaranDetail,
    Hotkey,
  },
  data() {
    return {
      submitted: false,
      accountService: null,
      list_kas: null,
      dialogAdd: false,
      dialogDelete: false,
      isLoading: false,
      isLoadingSaveHeader: false,
      isLoadingSaveDetail: false,
      detailIndex: -1,
      detail: null,
      deleted: [],
      saldo: 0,
      form: {
        id: 0,
        kk_at: new Date(),
        kk_no: '',
        kas: null,
        header: '',
        detail: [],
      },
      severity: 'warn',
      disableSaveButton: false,
    }
  },
  created() {
    this.accountService = new AccountService()
  },
  computed: {},
  mounted() {
    this.reloadData()
  },
  methods: {
    async getDaftarKas() {
      const daftarKasService = new DaftarKasService()
      return await daftarKasService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Daftar Kas', this)
        })
    },
    onLoadSaldo() {
      if (this.form.kas) {
        return (this.saldo = this.form.kas.saldo)
      }

      return 0
    },
    add() {
      this.detailIndex = -1
      this.detail = {
        account: null,
        keterangan: '',
        reff_no: '',
        nilai: 0,
      }
      this.dialogAdd = true
    },
    async onEditDetail(item) {
      this.detailIndex = this.form.detail.findIndex(
        (el) => el.id === item.id && el.account.id === item.account.id
      )
      this.detail = Object.assign({}, item)
      this.dialogAdd = true
    },
    onConfirmDeletion(item) {
      this.detail = Object.assign({}, item)
      this.dialogDelete = true
    },
    onDeleteDetail() {
      if (this.detail.id !== 0) {
        this.deleted.push(this.detail.id)
      }
      const index = this.form.detail.findIndex(
        (el) =>
          el.id === this.detail.id &&
          el.account.id === this.detail.account.id &&
          el.uuid === this.detail.uuid
      )
      this.calculateSaldoHapus()
      this.form.detail.splice(index, 1)
      this.detailIndex = -1
      this.detail = {}
      this.dialogDelete = false

      this.calculateSaldo()
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      if (!this.form.detail) {
        return
      }

      const form = Object.assign({}, this.form)

      // const pengeluaran = this.form.detail.reduce(
      //   (acc, total) => acc + parseInt(total.nilai),
      //   0
      // )

      // if (this.saldo < pengeluaran) {
      //   this.$toast.add({
      //     severity: 'warn',
      //     summary: 'Rincian Pengeluaran',
      //     detail:
      //       'Total rincian melebihi saldo ' +
      //       this.form.kas.nama +
      //       '. Pengeluaran baru tidak dapat ditambahkan.',
      //     life: 3000,
      //   })
      //   return
      // }

      form.kk_at = this.form.kk_at
        ? dayjs(this.form.kk_at).format('YYYY-MM-DD HH:mm:ss')
        : null

      form.kas_id = this.form.kas.id
      delete form.kas

      if (this.form.detail) {
        let detail = this.form.detail
        detail.forEach((d) => {
          d.account_id = d.account.id
          if (this.form.id > 0) {
            if (d.id == null) {
              d.id = 0
            }
          }
          delete d.account
        })
        form.detail = detail
      }

      if (this.form.id === 0) {
        delete form.id
        this.isLoadingSaveHeader = true
        this.accountService
          .addPengeluaran(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Pengeluaran',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'CashOut' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Pengeluaran', this)
          })
          .finally(() => (this.isLoadingSaveHeader = false))
      } else {
        this.isLoadingSaveHeader = true
        form.deleted = this.deleted
        this.accountService
          .updatePengeluaran(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Pengeluaran',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Pengeluaran', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSaveHeader = false
            this.reloadData()
          })
      }
    },
    reloadData() {
      const self = this
      Promise.all([this.getDaftarKas()]).then(function (result) {
        self.list_kas = result[0]

        if (self.id > 0) {
          self.isLoading = true
          self.accountService
            .getPengeluaran(`/${self.id}`)
            .then((res) => {
              if (res.data.status === 200) {
                self.form = res.data.data
                self.form.detail = res.data.data.detail
                self.form.kk_at = self.form.kk_at
                  ? new Date(self.form.kk_at)
                  : null
                self.form.kas = res.data.data.kas

                self.form.detail.forEach((element) => {
                  element.lastNilai = element.nilai
                  element.selisihNilai = 0
                  element.uuid = UUID.generate()
                })

                self.list_kas.forEach((element) => {
                  if (element.id === self.form.kas.id) {
                    self.form.kas.saldo = element.saldo
                    self.saldo = element.saldo
                  }
                })
              }
            })
            .catch((err) => {
              errorHandler(err, 'Pengeluaran', self)
            })
            .finally(() => (self.isLoading = false))
        }
      })
    },
    onSaveDetail(item) {
      this.detail = Object.assign({}, item)
      if (this.detailIndex >= 0) {
        this.detail.selisihNilai = this.detail.lastNilai - this.detail.nilai
        Object.assign(this.form.detail[this.detailIndex], this.detail)
        this.dialogAdd = false
      } else {
        this.detail.uuid = UUID.generate()
        this.detail.lastNilai = 0
        this.detail.selisihNilai = this.detail.lastNilai - this.detail.nilai
        this.form.detail.push(this.detail)
        this.$toast.add({
          severity: 'success',
          summary: 'Rincian Pengeluaran',
          detail: 'Data rincian berhasil ditambahkan.',
          life: 3000,
        })
        this.dialogAdd = false
      }

      this.isLoadingSaveDetail = false

      this.calculateSaldo()
    },
    onCloseFormDetail() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
    calculateSaldoHapus() {
      if (this.detail.id !== undefined) {
        if (this.detail.id !== null) {
          if (this.detail.id > 0) {
            for (let index = 0; index < this.list_kas.length; index++) {
              const element = this.list_kas[index]
              this.list_kas[index].saldo = element.saldo + this.detail.lastNilai
            }
          }
        }
      }
    },
    calculateSaldo() {
      let saldoDb = 0
      this.list_kas.forEach((element) => {
        if (element.id === this.form.kas.id) {
          saldoDb = element.saldo
        }
      })

      let selisih = 0
      this.form.detail.forEach((element) => {
        selisih = selisih + element.selisihNilai
      })

      this.saldo = saldoDb + selisih
    },
  },
  validations() {
    return {
      form: {
        kas: {
          required: helpers.withMessage('Kode Kas/Bank harus diisi.', required),
        },
        keterangan: {
          required: helpers.withMessage('Keterangan harus diisi.', required),
        },
      },
    }
  },
}
</script>
